
import { mapGetters, mapActions } from 'vuex';
import vClickOutside from 'click-outside-vue3';
import WCFavoriteButton from '@/modules/lists/components/WCFavoriteButton/WCFavoriteButton.vue';
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import { PROMO_RIBBON_TYPE } from '@/constants/PromoRibbonConstants';
import ProductImageInfoMixin from '@/mixins/ProductImageInfoMixin';
import WCProductPricing from '@/components/WCProductPricing/WCProductPricing.vue';
import WCPromoRibbon from '@/components/WCPromoRibbon/WCPromoRibbon.vue';
import WCCustomizeButton from '@/modules/cart/components/WCCustomizeButton/WCCustomizeButton.vue';
import WCImage from '@/components/WCImage/WCImage.vue';
import ExitScanAndGo from '@/views/ScanProduct/ExitScanAndGo/ExitScanAndGo.vue';
import ModalService from '@/modules/modals/services/modal.service';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import ToastService from '@/modules/toasts/services/ToastService';
import WCAvailableCouponsModal from '@/modules/coupons/components/WCAvailableCouponsModal/WCAvailableCouponsModal.vue';
import WCSimpleToastVue from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import WCAddToOrderTypeCartToast from '@/modules/toasts/components/WCAddToOrderTypeCartToast/WCAddToOrderTypeCartToast.vue';
import ProductDetailMixin from '@/views/ProductDetailPage/mixins/ProductDetailMixin';
import HidePricingMixin from '@/mixins/HidePricingMixin';
import { BTN_VARIANT as ATLB_BTN_VARIANT } from '@/constants/AppConstants';
import WCPaymentOptionBadges from '@/components/WCPaymentOptionBadges/WCPaymentOptionBadges.vue';
import WCStoreSelectModal from '@/components/WCStoreSelectModal/WCStoreSelectModal.vue';
import { defineComponent, defineAsyncComponent } from 'vue';

export default defineComponent({
  emits: ['addToCart'],
  name: 'WCMobileProductCard',
  components: {
    WCBasicAddToCartButton: defineAsyncComponent(() =>
      import('@/modules/cart/components/WCBasicAddToCartButton/WCBasicAddToCartButton.vue'),
    ),
    WCFavoriteButton,
    WCProductPricing,
    WCPromoRibbon,
    WCCustomizeButton,
    WCImage,
    WCPaymentOptionBadges,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [
    CartControlsMixin,
    ProductImageInfoMixin,
    OrderTypesMixin,
    ProductDetailMixin,
    HidePricingMixin,
  ],
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      isCustomer: 'user/isCustomer',
      isCustomerModeScan: 'user/isCustomerModeScan',
      isInStore: 'cart/getInStore',
    }),
    addToListVariant() {
      return ATLB_BTN_VARIANT.MOBILE_CARD;
    },
    isMadeToOrderItem() {
      return this.item.hasModifiers || this.item.hasLinkedItems;
    },
    /**
     * Method to get the discount name ribbon key
     */
    discountNameRibbon() {
      return PROMO_RIBBON_TYPE.DISCOUNT_NAME_RIBBON;
    },
    // todo Deduplicate this from WCBasicAddToCartButton
    inStoreOnly() {
      return this.isInStoreOnly(this.item) && !this.isScanAndGoMode;
    },
    notAvailable() {
      return this.getMinimumQuantity(this.item).comparedTo(0) <= 0;
    },
    hasCoupons() {
      return this.item?.coupons && this.item?.coupons?.length > 0;
    },
    showAddToCartButton() {
      if (this.isItemListOnly(this.item)) {
        return true;
      }
      return (
        (!this.isInStock(this.item) ||
          this.showQuantityAdjuster(this.item) ||
          this.isInStoreOnly(this.item) ||
          this.getMinimumQuantity(this.item).comparedTo(0) <= 0) &&
        this.$configuration.cartEnabled
      );
    },
    showIncrementButton() {
      if (this.isItemListOnly(this.item)) {
        return false;
      }
      return (
        !this.isInStoreOnly(this.item) &&
        this.isInStock(this.item) &&
        this.getMinimumQuantity(this.item).comparedTo(0) > 0 &&
        !this.isCustomerModeScan &&
        this.$configuration.cartEnabled
      );
    },
    eligibleOrderTypes() {
      return this.eligibleOrderTypesForItem(this.item);
    },
  },
  data() {
    return {
      selected: '',
      placeholder: '',
      liked: false,
      quantityEdit: false,
    };
  },
  methods: {
    ...mapActions({ setInStore: 'cart/setInStore', reload: 'cart/reload', sync: 'cart/sync' }),
    isItemInCart(item) {
      return !this.getQuantityInCart(item).isZero();
    },
    showQuantityAdjuster(item) {
      return this.quantityEdit && !this.getQuantityInCart(item).isZero();
    },
    async openCouponsModal() {
      try {
        await ModalService.open(WCAvailableCouponsModal, { item: this.item });
      } catch (error) {
        if (error !== 'Dismissed')
          ToastService.open(WCSimpleToastVue, {
            props: {
              variant: 'danger',
              title: this.$t('error'),
              message: this.$t('showCouponMoreInfoFailure'),
            },
            timeout: 7000,
            actions: {},
            skipHash: false,
          });
      }
    },
    addToCart() {
      this.$emit('addToCart', this.item);
    },
    async quantityClickedIncrement(item) {
      await this.setInStore(this.isScanAndGoMode);

      if (this.$configuration.orderTypesEnabled && !this.isScanAndGoMode) {
        await this.alertNotShoppingFromHomeStore();
        this.showOrderTypeToast();
      } else if (this.isScanAndGoMode || (!this.isInStore && !this.isScanAndGoMode)) {
        this.quantityEdit = !this.quantityEdit;
        await this.increment(item);
      }

      if (this.isInStore && !this.isScanAndGoMode) {
        try {
          await ModalService.open(ExitScanAndGo);
        } catch (error) {
          this.dismiss('Canceled');
          console.error(error);
        }
      }
    },
    quantityClicked() {
      this.quantityEdit = !this.quantityEdit;
    },
    hide() {
      this.quantityEdit = false;
    },
    showOrderTypeToast() {
      ToastService.open(WCAddToOrderTypeCartToast, {
        props: {
          item: this.item,
          title: this.$t('verifyOrderType'),
          orderTypes: this.eligibleOrderTypes,
        },
        timeout: 0,
        actions: {},
        skipHash: false,
      });
    },
    async alertNotShoppingFromHomeStore() {
      if (
        this.isEmpty &&
        this.getUser?.home !== this.$configuration.store.id &&
        this.$configuration.multistore
      )
        try {
          await ModalService.open(WCStoreSelectModal);
        } catch (error) {
          console.log(error);
        }
    },
  },
});
