import { resolveComponent as _resolveComponent, vShow as _vShow, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33ea55fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wc-card__wrapper" }
const _hoisted_2 = { class: "wc-card card shadow-sm rounded" }
const _hoisted_3 = { class: "wc-card__top d-flex flex-column align-items-center justify-content-center" }
const _hoisted_4 = {
  key: 0,
  class: "wc-hide-price-sign-in font-size-s"
}
const _hoisted_5 = ["aria-label"]
const _hoisted_6 = {
  class: "sr-only",
  "aria-live": "assertive"
}
const _hoisted_7 = { class: "wc-promo-ribbon-wrap" }
const _hoisted_8 = { class: "wc-card__body d-flex font-size-xs p-2" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = {
  key: 0,
  class: "wc-card__price text-right"
}
const _hoisted_11 = { class: "wc-card__footer d-flex flex-column font-size-xs flex-grow-1 justify-content-between" }
const _hoisted_12 = { class: "d-flex flex-row justify-content-between" }
const _hoisted_13 = { class: "wc-card__brand text-truncate ml-2 mb-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WCBasicAddToCartButton = _resolveComponent("WCBasicAddToCartButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_WCFavoriteButton = _resolveComponent("WCFavoriteButton")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_WCCustomizeButton = _resolveComponent("WCCustomizeButton")!
  const _component_WCImage = _resolveComponent("WCImage")!
  const _component_WCPromoRibbon = _resolveComponent("WCPromoRibbon")!
  const _component_WCProductPricing = _resolveComponent("WCProductPricing")!
  const _component_WCPaymentOptionBadges = _resolveComponent("WCPaymentOptionBadges")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_WCBasicAddToCartButton, {
      class: "quantity-overlay flex-column end-0",
      item: _ctx.item,
      dropdownVariant: false,
      addToListVariant: _ctx.addToListVariant
    }, null, 8, ["item", "addToListVariant"]), [
      [_vShow, _ctx.showAddToCartButton],
      [_directive_click_outside, _ctx.hide]
    ]),
    _createTextVNode(),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.shouldHideItemPrices)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_router_link, {
                class: "badge badge-primary badge-pill",
                to: "/login"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('signInToShop')), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createTextVNode(),
        (_ctx.isCustomer)
          ? (_openBlock(), _createBlock(_component_WCFavoriteButton, {
              key: 1,
              class: "wc-card__heart-icon ml-1 mt-1 p-1",
              item: _ctx.item,
              isMobile: true
            }, null, 8, ["item"]))
          : _createCommentVNode("", true),
        _createTextVNode(),
        (_ctx.showIncrementButton && !_ctx.shouldHideItemPrices)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (_ctx.$configuration.orderTypesEnabled || (!_ctx.isItemInCart(_ctx.item) && !_ctx.isMadeToOrderItem))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.quantityClickedIncrement(_ctx.item))),
                    "aria-label": _ctx.$t('addToCart'),
                    class: "unstyled-btn wc-card__add-icon mr-2 mt-2"
                  }, [
                    _createVNode(_component_font_awesome_icon, {
                      icon: "plus",
                      size: "lg"
                    })
                  ], 8, _hoisted_5))
                : (_ctx.isMadeToOrderItem)
                  ? (_openBlock(), _createBlock(_component_WCCustomizeButton, {
                      key: 1,
                      item: _ctx.item,
                      class: "wc-card__add-icon mr-2 mt-2 btn-primary"
                    }, null, 8, ["item"]))
                  : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        _createTextVNode(),
        (
            _ctx.isItemInCart(_ctx.item) &&
              _ctx.$configuration.cartEnabled &&
              !_ctx.isItemListOnly(_ctx.item) &&
              !_ctx.$configuration.orderTypesEnabled
          )
          ? (_openBlock(), _createElementBlock("button", {
              key: 3,
              class: "unstyled-btn quantity-badge mr-2 mt-2 font-size-sm",
              onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.quantityClicked && _ctx.quantityClicked(...args)), ["stop"]))
            }, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('quantity')), 1),
              _createTextVNode(_toDisplayString(_ctx.getDisplayQuantityInCart(_ctx.item)), 1)
            ]))
          : _createCommentVNode("", true),
        _createTextVNode(),
        _createVNode(_component_router_link, {
          to: `/i/${_ctx.item.id}`,
          class: "d-flex flex-column align-items-center justify-content-center h-100 w-100"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_WCImage, {
              imageSrc: _ctx.getProductImage(_ctx.item.image),
              imageAlt: _ctx.productImgAltText(_ctx.item.name, _ctx.item.brand),
              imageClass: "wc-card__img",
              defaultClass: "wc-card__camera-icon",
              defaultTitle: _ctx.noProductImageAltText(_ctx.item.name)
            }, null, 8, ["imageSrc", "imageAlt", "defaultTitle"])
          ]),
          _: 1
        }, 8, ["to"]),
        _createTextVNode(),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_WCPromoRibbon, {
            product: _ctx.item,
            ribbonType: _ctx.discountNameRibbon
          }, null, 8, ["product", "ribbonType"])
        ]),
        _createTextVNode()
      ]),
      _createTextVNode(),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          class: "wc-card__name flex-grow-1 text-wrap pr-2 text-break",
          onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"]))
        }, [
          _createVNode(_component_router_link, {
            to: `/i/${_ctx.item.id}`,
            class: "underline-link-from-center font-weight-bold multiline-ellipsis",
            ref: "itemLink",
            "aria-label": `Product ${_ctx.item.name}`
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.item.name) + " ", 1),
              (_ctx.item.size)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.item.size), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["to", "aria-label"])
        ]),
        _createTextVNode(),
        (!_ctx.shouldHideItemPrices)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_WCProductPricing, {
                class: "wc-card__item-pricing text-truncate",
                item: _ctx.item
              }, null, 8, ["item"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createTextVNode(),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            (_ctx.item.brand)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: `/b?g=BRD-${_ctx.item.brandId}`,
                  class: "underline-link-from-center",
                  "aria-label": `Brand ${_ctx.item.brand}`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.item.brand), 1)
                  ]),
                  _: 1
                }, 8, ["to", "aria-label"]))
              : _createCommentVNode("", true)
          ]),
          _createTextVNode(),
          _createElementVNode("div", {
            class: "wc-coupon-card-mobile__coupon-icon link-primary mr-2 pb-1",
            onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openCouponsModal && _ctx.openCouponsModal(...args)), ["stop"]))
          }, [
            (_ctx.isCustomer && _ctx.$configuration.allowCouponClipping && _ctx.hasCoupons)
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 0,
                  icon: "scissors",
                  size: "lg"
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _createTextVNode(),
        _createVNode(_component_WCPaymentOptionBadges, {
          class: "pb-1",
          item: _ctx.item
        }, null, 8, ["item"])
      ]),
      _createTextVNode()
    ]),
    _createTextVNode()
  ]))
}